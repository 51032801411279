$color-black-real: #000;
$color-white-real: #fff;

$color-white: $color-white-real;
$color-black: $color-black-real;

$color-gray: #808080;

$color-blue-dim: #007d96;
$color-blue-lit: #02accd;

$color-orange-dim: #b68c00;
$color-orange-lit: #e0ad00;

$color-map: #2e4e4e;
$color-map-light: #3f5f60;

@mixin neg {
  -webkit-font-smoothing: antialiased !important;
}