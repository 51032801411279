.menu {
  li {
    display: inline-block;
    margin-left: 1vw;
    a {
      display: block;
      color: $color-gray;
      text-decoration: none;
      font-weight: 500;
      font-size: 2.7vh;

      &:hover {
        color: $color-black;
      }
    }
  }
}