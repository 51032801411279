$dock-animation-duration: 0.2s;

$control-height-large: 8vh;
$control-height-small: 5vh;

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 13.5%;
}

.map_tag {

  position: absolute;
  left: 0;
  top: 13.5%;
  height: 65%;
  width: 100%;
  transition: width $dock-animation-duration, margin-left $dock-animation-duration;
  margin-left: 0;

  &.docked-right {
    width: 66%;
  }

  &.docked-left {
    width: 66%;
    margin-left: 33%;
  }
}

.info_pane {
  position: absolute;
  width: 33%;
  top: 13.5%;
  height: 66.6%;
  overflow: scroll;
  padding: 0 20px;
  box-sizing: border-box;

  transition: left 0.5s;
}

.info_pane-left {
  left: -33%;

  &.visible {
    left: 0;
  }
}

.info_pane-right {
  left: 100%;

  &.visible {
    left: 66%;
  }
}

.help-banner-left {
  position: absolute;
  bottom: 13vh;
  left: 0;
}

.help-banner-right {
  position: absolute;
  bottom: 13vh;
  right: 0;
}

.controls {
  position: absolute;
  bottom: 3vh;
  width: 100%;
  height: $control-height-large;
  padding: 0 30px;

  .controls-tools {
    width: 50%;
    height: 100%;
    float: left;

    .controls-commands {
      display: inline-block;
      margin-left: 2vw;
    }
  }

  .controls-projections {
    width: 50%;
    float: left;
    text-align: right;
  }
}

.controls_tag {
  margin-top: 100%;
}