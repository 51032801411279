.graticule {
  fill: none;
  stroke: $color-map-light;
  stroke-width: .5px;
  stroke-opacity: 1;
}

.land {
  fill: $color-map;
  fill-opacity: 1;
}

.boundary {
  fill: none;
  stroke: $color-black;
  stroke-width: .5px;
}

.map_tag {
  border: none;
}

.raster-visible {
  .stroke {
    fill: none;
  }
}

.coords_tag, .loxo_tag, .geo_tag {
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 0px 5px;
}

.geo_tag {
  bottom: 32px;
}

.coords_tag {
  font-size: 16px;
}