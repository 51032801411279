.header {
  h1 {
    display: block;
    height: 100%;
    width: 100%;
    margin: 0;
    font-size: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .intro {
    display: block;
    height: 100%;
    width: 60%;
    margin: 0 auto;
  }

  .info {
    display: block;
    position: absolute;
    top: 5.8vh;
    right: 9.5vw;
    width: $control-height-small;
    height: $control-height-small;
    background-image: url('../img/ic_info.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  ul.lang-selector {
    position: absolute;
    right: 2.3vw;
    top: 4.2vh;
    margin: 0;
    padding: 0;
    li {
      line-height: 3.5vh;
      list-style: none;
      a {
        color: #373737;
        text-decoration: none;
        font-weight: 500;
        font-size: 2.7vh;

        &:hover {
          color: $color-white;
        }
      }
    }
  }

  .menu {
    position: absolute;
    right: 2.3vw;
    top: 4.2vh;
    ul.lang-selector {
      display: inline-block;
      position: static;
      li {
        margin: 0;
        display: inline-block;
        a.active {
          display: none;
        }
      }
    }
  }
}

.lang-en .header h1 {
  background-image: url('../img/logo_en.png');
}

.lang-pt .header h1 {
  background-image: url('../img/logo_pt.png');
}