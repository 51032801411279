@function _gradient-positions-parser($gradient-type, $gradient-positions) {
  @if $output-bourbon-deprecation-warnings == true {
    @warn "[Bourbon] [Deprecation] `_gradient-positions-parser` is " +
    "deprecated and will be removed in 5.0.0.";
  }

  @if $gradient-positions
  and ($gradient-type == linear)
  and (type-of($gradient-positions) != color) {
    $gradient-positions: _linear-positions-parser($gradient-positions);
  }
  @else if $gradient-positions
  and ($gradient-type == radial)
  and (type-of($gradient-positions) != color) {
    $gradient-positions: _radial-positions-parser($gradient-positions);
  }
  @return $gradient-positions;
}
