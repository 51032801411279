.controls {
  box-sizing: border-box;
  left: 0;
}

.controls-tools {
  [data-ui-tool] {
    position: relative;
    display: inline-block;
    width: $control-height-large;
    height: $control-height-large;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 1.5vh;

    &:hover, &.active {
      &:before {
        content: " ";
        display: block;
        width: 120%;
        height: 120%;
        position: absolute;
        z-index: -1;
        top: -10%;
        left: -10%;
        border-radius: 50%;
        background-color: $color-blue-dim;
      }
    }
  }

  [data-ui-tool=rotate] {
    background-image: url('../img/ic_rotate.png');
  }

  [data-ui-tool=indicatrix] {
    background-image: url('../img/ic_indicatrix.png');
  }

  [data-ui-tool=loxodrome] {
    background-image: url('../img/ic_loxodrome.png');
  }

  [data-ui-tool=geodesic] {
    background-image: url('../img/ic_geodesic.png');
  }

  .controls-commands {
    [data-ui-command] {
      display: inline-block;
      background-repeat: no-repeat;
      width: $control-height-small;
      height: $control-height-small;
    }
  }

  [data-ui-command=undo] {
    background-image: url('../img/ic_undo.png');
    background-size: contain;
  }

  [data-ui-command=clear] {
    background-image: url('../img/ic_clear.png');
    background-size: contain;
  }
}

.controls-projections {

  padding-top: $control-height-large - $control-height-small;

  [data-ui-projection] {
    display: inline-block;
    background-color: $color-gray;
    width: $control-height-small;
    height: $control-height-small;
    border-radius: 50%;
    text-align: center;
    font-size: $control-height-small / 2;
    line-height: $control-height-small;
    font-weight: 300;
    text-decoration: none;
    color: $color-black;
    margin-left: 0.2vw;

    &.active, &:hover {
      background-color: $color-orange-lit;
    }
  }
}