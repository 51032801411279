.info_pane-close {
  position: absolute;
  top: 3.8%;
  right: 2.3%;
  display: block;
  width: 10%;
  height: 0;
  padding-bottom: 10%;
  background-image: url('../img/ic_close.png');
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    background-position: 0 100%;
  }
}